import { useStaticQuery, graphql } from "gatsby"

const useFlexmedImages = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "flexmed__hero--heart.png" }) {
        childImageSharp {
          fluid(toFormat: PNG, quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fullYearRegimen: file(
        relativePath: { eq: "icons/me__full-year-regimen.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      teleconsult: file(relativePath: { eq: "icons/me__teleconsult.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nationwideDelivery: file(relativePath: { eq: "icons/me__delivery.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      formulary: file(relativePath: { eq: "icons/me__formulary.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eligibilityDoctor: file(
        relativePath: { eq: "icons/me__required--eligibility.png" }
      ) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescribingDoctor: file(
        relativePath: { eq: "icons/me__required--doctor.png" }
      ) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      requiredDocuments: file(
        relativePath: { eq: "icons/me__required--document.png" }
      ) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      searchMedicines: file(
        relativePath: { eq: "icons/me__search-medicines.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      provideDetails: file(
        relativePath: { eq: "icons/me__provide-details.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      receiveMedicines: file(relativePath: { eq: "icons/me__blue-pack.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      arrow: file(relativePath: { eq: "icons/triangle__grey.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      successIcon: file(relativePath: { eq: "icons/me__check.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useFlexmedImages
