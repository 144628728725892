import React from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import useFlexmedImages from "./hooks/useFlexmedImages"

const FlexMedHome = (props) => {
  const { pageContext } = props
  const { nextPath } = pageContext
  const module = pageContext?.module
  const data = useFlexmedImages()
  const heart = data.heart.childImageSharp.fluid.src

  return (
    <Layout
      pageContext={pageContext}
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Foundever | FlexMed"
    >
      <Hero
        size="medium"
        background={{
          image: heart,
          position: "center",
        }}
      >
        <Container isCentered desktop={10} fullhd={8}>
          <Container mobile={12} tablet={8} desktop={8} padding={0}>
            <h1 className="title mb-3 has-text-secondary">{module?.title}</h1>
            <p
              className="subtitle mb-2"
              dangerouslySetInnerHTML={{ __html: module?.subtitle }}
            />
            <Button
              color="orange"
              size="medium"
              onClick={() => navigate(nextPath)}
            >
              Order Cashless
            </Button>
            <p
              className="subtitle mt-1"
              dangerouslySetInnerHTML={{ __html: module?.buttonSubtitle }}
            />
          </Container>
        </Container>
      </Hero>
    </Layout>
  )
}

export default FlexMedHome
